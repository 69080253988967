import React from 'react'
import Layout from '../components/layout'
import { Hero } from 'fux'
import superhero from './../images/api-hero.jpg'

const IndexPage = props => {
  let theme = 'dark'
  if (props && props.path && props.path === '/') theme = 'dark'
  return (
    <Layout theme={theme}>
      <Hero
        item={
          {
            fields: {
              heroType: 'superhero',
              headline: 'TT Nyhetsbyrån APIs',
              subHeadline: 'Use Content API to find and push content from us and Media API to fetch media.',
              backgroundColor: '#4C565C',
              cta: [
                {
                  fields: {
                    text: 'Content API',
                    url: '/api/content-api/',
                    image:'/content-api.png'
                  }
                },
                {
                  fields: {
                    text: 'Media API',
                    url: '/api/media-api',
                    image:'/media-api.png'
                  }
                },
                {
                  fields: {
                    text: 'APIs Overview',
                    url: '/api',
                    image:'/api-overview.png'
                  }
                },
                {
                  fields: {
                    text: 'Authentication',
                    url: '/api/oauth2/',
                    image:'/oauth.png'
                  }
                }
              ]
            }
          }
        }
      />
      <div
        style={{
          padding: 40,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#fffce6',
          fontWeight: 'bold',
          fontSize: 20
        }}
      >
        <a style={{ cursor: 'pointer' }} href='/delivery/ip-addresses'>Click here to find the IP addresses for our deliveries</a>
      </div>
      <Hero
        item={
          {
            fields: {
              heroType: 'leaflethero',
              headline: 'Specifications',
              backgroundColor: '#DAE6E1',
              leaflet: [
                {
                  fields: {
                    headline: 'TTNinjs',
                    image: null,
                    listItems: ['JSON', 'IPTC-standard'],
                    cta: {
                      fields: {
                        url: '/ttninjs'
                      }
                    }
                  }
                },
                {
                  fields: {
                    headline: 'TTNewsML',
                    image: null,
                    listItems: ['XML', 'IPTC-standard'],
                    cta: {
                      fields: {
                        url: '/ttnewsml'
                      }
                    }
                  }
                },
                {
                  fields: {
                    headline: 'Metadata',
                    image: null,
                    listItems: ['Examples', 'Specifications'],
                    cta: {
                      fields: {
                        url: '/metadata'
                      }
                    }
                  }
                },
                {
                  fields: {
                    headline: 'Namespaces',
                    image: null,
                    listItems: ['Codes', 'Schemas', 'And more'],
                    cta: {
                      fields: {
                        url: '/namespaces'
                      }
                    }
                  }
                }
              ]
            }
          }
        }
      />
    </Layout>
  )
}

export default IndexPage
